import React from 'react'
import { Grid, Paper } from '@mui/material'

export default function CSCart() {
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '95vh',
                }}
            >

                CSCart

            </Paper>
        </Grid>
    )
}
