import React from 'react';
import { List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function MenuItems(props) {
    // console.log(props.selectedIndex)

    return (
        <List component="nav" >
            <ListItemButton selected={props.selectedIndex === 0 ? true : false} onClick={() => props.setSelectedMenu(0)}>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Ebay" />
            </ListItemButton>
            <ListItemButton selected={props.selectedIndex === 1 ? true : false} onClick={() => props.setSelectedMenu(1)}>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Shopify" />
            </ListItemButton>
            <ListItemButton selected={props.selectedIndex === 2 ? true : false} onClick={() => props.setSelectedMenu(2)}>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="CS-Cart" />
            </ListItemButton>
        </List>
    )
}
