import Home from './Pages/Home';
// import { BrowserRouter, Route } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
      {/* <BrowserRouter>
        <Route exact path="/"><Home /></Route>
      </BrowserRouter> */}

      <Home />
    </div>
  );
}

export default App;
